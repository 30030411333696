html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

canvas.deepar {
  border: 0px none;
  background-color: black;
  display: block;
  margin: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.menu {
  position: absolute;
  display: flex;
  top: 0;
  right: 0px;
  width: 100%;
  height: 220px;
  background-color: #fff;
  text-align: center;
  align-items: center;
  z-index: 1000;
  justify-content: center;
}

#feet-text {
  position: absolute;
  top: 80%;
  left: 0%;
  text-align: center;
  width: 100%;
  color: black;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;
}


@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

